import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../sass/main.scss"
import {
  Layout,
  SEO,
  Scrollable,
  HeadSection,
  ImageSection,
  Image,
} from "../components"
import { Row, Col, Container } from "react-bootstrap"
import { ClientsServicesTweetsQuery } from "../graphql"
import Img from "gatsby-image/withIEPolyfill"
import Slugify from "slugify"

const IndexPage = () => {
  // Replace client title inside query below to change clients logo on homepage.
  const data: ClientsServicesTweetsQuery = useStaticQuery(graphql`
    query ClientsServicesTweets {
      clients: allClient(
        filter: {
          title: {
            in: [
              "DS Smith"
              "DFS / Delivery"
              "Odeon"
              "Techdata"
              "Gigaclear"
              "Tesco"
            ]
          }
        }
        sort: { order: DESC, fields: order }
      ) {
        edges {
          node {
            id
            title
            logo
          }
        }
      }
      services: allFile(filter: { relativeDirectory: { eq: "services" } }) {
        edges {
          node {
            id
            publicURL
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1024) {
                ...GatsbyImageSharpFluid
              }
            }
            name
            extension
          }
        }
      }
      twitter: file(relativePath: { eq: "twitter.svg" }) {
        publicURL
      }
      placeholder: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tweets: allTweet(sort: { order: DESC, fields: order }) {
        edges {
          node {
            id
            tweet
            endpoint
            user
            order
          }
        }
      }
    }
  `)
  const headTitle = (): string => {
    return "Enterprise AI"
  }
  const headSubTitle = (): string => {
    return "We use artificial intelligence to solve exponentially hard efficiency problems."
  }
  const headDescription = (): string => {
    return `We're 140 people, distributed across Europe. For the last 12 years, we've been building enterprise AI technologies for some of the world's best known organisations, including Tesco and PwC. Most companies that touch data are calling themselves an AI company — they're not. We're one of the few companies combining machine learning with optimisation to build decision making systems that radically improve operational efficiency.`
  }
  const imageDescription = () => {
    return (
      <Col xs="3" sm="7" md="8" lg="9" className="z-index-2 mb-150 offset-lg-2">
        <div className="st-block st-block--transparent st-block--no-padding">
          <h3>
            Machine learning is great at finding patterns from data, but it's
            terrible at making decisions.{" "}
            <span className="font-weight-medium">
              That’s the role of optimisation. A different, much less talked
              about technology.
            </span>
          </h3>
        </div>
      </Col>
    )
  }
  const imageContent = () => {
    return (
      <>
        <Row className="justify-content-lg-end">
          <Col xs="3" sm="4" md="10" lg="12" className="mb-32">
            <p className="font-weight-medium">Scheduling people.</p>
            <p className="font-weight-medium">Routing vehicles.</p>
            <p className="font-weight-medium">Planning infrastructure.</p>
            <p className="font-weight-medium">Optimising warehouses.</p>
            <p className="font-weight-medium">Allocating inventory.</p>
          </Col>
          <Col xs="3" sm="8" md="10" lg="12">
            <p className="mb-32">
              These aren’t machine learning problems. They’re optimisation
              problems.
              <br />
              <br />
              We've delivered our keynote over 100 times, in 28 countries,
              educating thousands of executives on what AI actually is, and what
              impact it will have on their business.
            </p>
            <Link to="ai-strategy" className="learn-more" title="AI Strategy">
              Learn about AI Strategy
            </Link>
          </Col>
        </Row>
      </>
    )
  }
  const getServicesImage = (name: string) => {
    let image = data.services.edges.find((x) => x.node.name === name)
    if (!image) {
      image = data.placeholder.childImageSharp.fluid
      return image
    }
    return image.node.childImageSharp.fluid
  }

  const renderCaseStudy = (node: any, index: any) => {
    return (
      <Col
        xs="1"
        sm="2"
        md="3"
        lg="4"
        className="organisation__item"
        key={"case-" + index}
      >
        <div className="st-block">
          <Link
            to={"/clients/" + Slugify(node.node.title.toLowerCase())}
            title={node.node.title}
          >
            <Image
              path={node.node.logo}
              isSquare={false}
              alt={node.node.title}
            />
          </Link>
        </div>
      </Col>
    )
  }
  return (
    <Layout step={1}>
      <SEO
        title="Satalia - Enterprise AI systems"
        description="Satalia builds and applies AI technology to solve efficiency problems for organisations such as Tesco and PwC."
        twitterTitle="Satalia builds enterprise AI systems"
        twitterDescription="Satalia builds and applies AI technology to solve efficiency problems for organisations such as Tesco and PwC."
        openGraphTitle="Satalia builds enterprise AI systems"
        openGraphDescription="Satalia builds AI technology to solve efficiency problems."
      />
      <Container>
        <section id="enterprise" className="mb-95">
          <HeadSection
            title={headTitle()}
            subtitle={headSubTitle()}
            description={headDescription()}
          ></HeadSection>
        </section>
        <section id="products-services" className="mb-285">
          <Row className="justify-content-center">
            <Col xs="3" sm="4" md="5" lg="3">
              <div
                className="st-block delivery"
                data-aos="fade-up"
                data-aos-delay="0"
              >
                <h2 className="st-block__title  pb-0">Products</h2>
                <Img
                  loading="eager"
                  alt="satalia-delivery"
                  fluid={getServicesImage("satalia-delivery")}
                ></Img>
                <h3>Satalia Delivery</h3>
                <p>
                  Optimises the routes and schedules of any vehicle fleet. Saves
                  money, reduces environmental impact and improves customer
                  choice.
                </p>
                <Link
                  to="/delivery"
                  className="learn-more"
                  title="satalia-delivery"
                >
                  Learn more
                </Link>
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="3">
              <div
                className="st-block workforce"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <Img
                  loading="eager"
                  alt="satalia-workforce"
                  fluid={getServicesImage("satalia-workforce")}
                ></Img>
                <h3>Satalia Workforce</h3>
                <p>
                  Dynamically automates and optimises the allocation of people
                  to their work. Improves profitability, employee and customer
                  satisfaction.
                </p>
                <Link
                  to="/workforce"
                  className="learn-more"
                  title="Satalia Workforce"
                >
                  Learn more
                </Link>
              </div>
            </Col>
            <Col className="services" xs="3" sm="4" md="5" lg="3">
              <div
                className="st-block custom-ai"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <h2 className="st-block__title pb-0">Services</h2>
                <Img
                  loading="eager"
                  alt="satalia-custom-ai"
                  fluid={getServicesImage("satalia-custom-ai")}
                ></Img>
                <h3>Custom AI</h3>
                <p>
                  Your extended AI team. We scope, prototype and productionise
                  custom-made AI solutions that learn, adapt and improve over
                  time.
                </p>
                <Link
                  to="/custom-ai"
                  className="learn-more"
                  title="Satalia Custom AI"
                >
                  Learn more
                </Link>
              </div>
            </Col>
            <Col xs="3" sm="4" md="5" lg="3">
              <div
                className="st-block ai-strategy"
                data-aos="fade-up"
                data-aos-delay="900"
              >
                <Img
                  loading="eager"
                  alt="satalia-ai-strategy"
                  fluid={getServicesImage("satalia-ai-strategy")}
                ></Img>
                <h3>AI Strategy</h3>
                <p>
                  We help leadership teams develop AI strategies that align with
                  their objectives, and advise on the talent, partners and
                  technologies they'll need to execute.
                </p>
                <Link
                  to="/ai-strategy"
                  className="learn-more"
                  title="AI Strategy"
                >
                  Learn more
                </Link>
              </div>
            </Col>
          </Row>
        </section>
      </Container>
      <Container>
        <section id="organisations" className="mb-300">
          <Row className="justify-content-center">
            <Col xs="3" sm="6" md="8" lg="10" xl="8">
              <div className="st-block st-block--transparent mb-21">
                <h3 className="p-0">
                  We've built AI technologies for some of the world's best known
                  organisations,
                  <strong>
                    {" "}
                    across industries and throughout the value chain.
                  </strong>
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="3" sm="8" md="10" lg="12" xl="12">
              <Row className="organisation__items justify-content-center">
                {data.clients.edges
                  .slice(0, 3)
                  .map((node, index) => renderCaseStudy(node, index))}
              </Row>
              <Row className="organisation__items justify-content-center">
                {data.clients.edges
                  .slice(Math.max(data.clients.edges.length - 3, 1))
                  .map((node, index) => renderCaseStudy(node, index))}
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs="3" sm="6" md="8" lg="10" xl="8">
              <Link to="clients" className="learn-more">
                View case studies
              </Link>
            </Col>
          </Row>
        </section>
      </Container>
      <Container>
        <section id="machine-learning" className="mb-285">
          <ImageSection
            description={imageDescription()}
            content={imageContent()}
            align="end"
            image="video"
            video="https://youtu.be/hndFmYKtysY?t=102"
            aos="fade-right"
          ></ImageSection>
        </section>
      </Container>
      <Container fluid>
        <section id="tweets" className="pb-75">
          <Scrollable
            itemSelector=".tweet__item"
            section="#tweets"
            leftIndex={1}
          >
            <Row className="tweet__items st-scroll">
              <div className="scroll-padding"></div>
              {data.tweets.edges.map((tweet, index) => {
                return (
                  <div className="tweet__item" key={index}>
                    <div className="st-block">
                      <Row className="h-100 flex-column">
                        <Col
                          xs="3"
                          sm="8"
                          md="10"
                          lg="12"
                          className="tweet__item-header"
                        >
                          <a
                            href={tweet.node.endpoint}
                            target="_blank"
                            rel="nofollow noreferrer"
                            title={tweet.node.user}
                          >
                            {tweet.node.user}
                          </a>
                        </Col>

                        <Col
                          xs="3"
                          sm="8"
                          md="10"
                          lg="12"
                          className="tweet__item-content"
                        >
                          <p
                            className="text-inherit"
                            dangerouslySetInnerHTML={{
                              __html: tweet.node.tweet,
                            }}
                          />
                        </Col>
                        <Col
                          xs="3"
                          sm="7"
                          md="9"
                          lg="9"
                          className="tweet__item-footer"
                        >
                          <a
                            href={tweet.node.endpoint}
                            target="_blank"
                            rel="nofollow noreferrer"
                            title={tweet.node.user}
                          >
                            <img src={data.twitter.publicURL} alt="twitter" />
                          </a>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )
              })}
              <div className="scroll-padding"></div>
            </Row>
          </Scrollable>
        </section>
      </Container>
    </Layout>
  )
}

export default IndexPage
